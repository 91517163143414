<template>
<div style="text-align: center; align-items: center;margin-top: 20%;">
  <h1>BaqytJol.kz</h1>
  <h2>Платформа находится в разработке.<br>Скоро открытие!</h2>
  <p style="font-size: 12px;color: gray;">Все права защищены ТОО "ИнфоТэч" 2024 г.</p>
</div>
</template>

<script>
export default {
  name: 'MainPage',
  data() {
    return {
      name: '',
      phone: '',
    };
  },
  methods: {
    submitForm() {
      // Здесь можно добавить логику для отправки данных на сервер
      console.log('Форма отправлена:', this.name, this.phone);
      // Очистка полей после отправки
      this.name = '';
      this.phone = '';
    },
  },
};
</script>

<style>
.contact-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='text'],
input[type='tel'] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
