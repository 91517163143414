import { createRouter, createWebHistory } from 'vue-router'
import SecretPohudeniyaPage from './views/SecretPohudeniyaPage.vue'
import DivorcePage from './views/DivorcePage.vue'
import NotFoundPage from './views/NotFoundPage.vue'
import ThankyouPage from './views/ThankyouPage.vue'
import MainPage from './views/MainPage.vue'

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage,
  },
  {
    path: '/7-secretov-pohudeniya',
    name: 'SecretPohudeniyaPage',
    component: SecretPohudeniyaPage,
  },
  {
    path: '/podat-na-razvod',
    name: 'DivorcePage',
    component: DivorcePage,
  },
  {
    path: '/thank-you',
    name: 'ThankyouPage',
    component: ThankyouPage,
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFoundPage',
    component: NotFoundPage,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
