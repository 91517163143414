<template>
<div style="text-align: center;">
      <h1>7 секретов похудения</h1>
        <div class="contact-form">
        <h2>Получи доступ к курсу по скидке 70%!</h2>
        <form @submit.prevent="submitForm">
        <div class="form-group">
            <label for="email">Email:</label>
            <input v-model="email" type="text" id="email" required />
        </div>
        <button type="submit">Получить курс</button>
        </form>
    </div>
    </div>
</template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'SecretPohudeniyaPage',
    data() {
      return {
        email: '',
      };
    },
    methods: {
    async submitForm() {
      try {
        const userAgent = window.navigator.userAgent;
        const apiUrl = process.env.VUE_APP_API_URL;
        // Отправляем данные на бэкенд
        const response = await axios.post(`${apiUrl}/api/signupcourse`, {
          email: this.email,
          courseId: 1,
          landId: 1,
          userAgent: userAgent,
        });

        // Здесь можно обработать ответ от сервера
        console.log('Ответ от сервера:', response.data);
        // Получаем ссылку на оплату из ответа
        const paymentUrl = response.data.paymentUrl;
        if (paymentUrl) {
          // Перенаправляем пользователя на страницу оплаты
          window.location.href = paymentUrl;
        } else {
          console.error('Не удалось получить ссылку на оплату');
        }

        // Очистка полей после успешной отправки
        this.email = '';
      } catch (error) {
        console.error('Erroe send Data:', error);
      }
    },
  },  
}
  </script>
  
<style>
</style>