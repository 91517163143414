<template>
  <div data-bs-theme="light" class="d-flex align-items-center justify-content-center vh-100">
    <main class="form-signin w-100 m-auto text-center">
      <h1 class="h3 mb-2 fw-normal">404 - Страница не найдена</h1>
      <p class="mt-3 mb-3 text-body-secondary">Страница которую Вы пытаетесь открыть не существует.</p>
      <router-link to="/dash">Вернуться на главную страницу</router-link>
      <p class="mt-5 mb-3 text-body-secondary">&copy; 2017–2023</p>
  </main>
  </div>
  </template>
  
  
  <script>
  export default {
    name: 'NotFoundPage',
  };
  </script>
  
  <style>
  </style>