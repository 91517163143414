<template>
    <div>
      <h1>Подать на развод</h1>
        <div class="contact-form">
        <h2>Оставьте заявку</h2>
        <form @submit.prevent="submitForm">
        <div class="form-group">
            <label for="name">Имя:</label>
            <input v-model="name" type="text" id="name" required />
        </div>
        <div class="form-group">
            <label for="phone">Телефон:</label>
            <input v-model="phone" type="tel" id="phone" required />
        </div>
        <button type="submit">Перезвоните мне</button>
        </form>
    </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';

  export default {
    name: 'DivorcePage',
    data() {
      return {
        name: '', // Определите свойство name
        phone: '', // Определите свойство phone
      };
    },
    methods: {
    async submitForm() {
      try {
        const userAgent = window.navigator.userAgent;
        // Отправляем данные на бэкенд
        const response = await axios.post('http://localhost:3000/newleads', {
          name: this.name,
          phone: this.phone,
          land_id: 2,
          useragent: userAgent,
          product_id: 2,
        });

        // Здесь можно обработать ответ от сервера
        console.log('Ответ от сервера:', response.data);
        this.$router.push('/thank-you');

        // Очистка полей после успешной отправки
        this.name = '';
        this.phone = '';
      } catch (error) {
        console.error('Ошибка отправки данных:', error);
      }
    },
  },  
}
  </script>
  
  <style>

  </style>