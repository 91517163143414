<template>
  <div>
		<router-view></router-view>
	</div>
</template>
<script>
export default {
  name: 'App',
}
</script>
<style>
html, body {
  background-color: #f5f5f5;
  
}
#app { 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
}
</style>
